import { amtStr } from 'containers/helper/numFormatter'
import { TaxType, useOutGoingDocTemplateLazyQuery } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import AddressConverter from '../addressConverter'
import { HotelXFolio } from '../CustomizedDocument/Default/Folio'
import { HotelXReceipt } from '../CustomizedDocument/Default/Receipt'
import { useGetTaxScheme } from 'containers/CommonSettingsModule/TaxScheme/hooks/useGetTaxScheme'
import ImperialLogo from 'assets/images/HotelImperial/ImperialLogo.jpg'
import { differenceInDays } from 'date-fns'
interface InvoiceProps {
  type: string
  stateInfo: any
  HotelDetails: any
  FolioDetails: any
  BIUsersLisiting: any
  GetCurrentGovTax: any
  GetServiceChargeTax: any
  DebtorFolioInfo:any
  AdvancePaymentDetails:any
  DepositClass:any
  FolioDetailsWithIDs:any
}
export const useInvoice = ({
  type,
  stateInfo,
  HotelDetails,
  FolioDetails,
  BIUsersLisiting,
  GetServiceChargeTax,
  GetCurrentGovTax,
  FolioDetailsWithIDs,
  DebtorFolioInfo,
  AdvancePaymentDetails,
  DepositClass,

}: InvoiceProps) => {
  const dateFormat = require('dateformat')
  console.log(stateInfo, 'USEINVOICE')
  const Payment = FolioDetails?.Payment
    ? FolioDetails?.Payment.filter(x => x.PaymentClass === 'Refund').length
    : null

  const Charge = FolioDetails?.Payment
    ? FolioDetails?.Payment.filter(x => {
        return x.PaymentClass === 'Refund'
      })[0]?.ReceiptNo
    : null

  const isNonGuestFolio = FolioDetails?.FolioType === 'NonGuest'
  const NonGuestFolioTitle = FolioDetails?.NonGuestInfo?.Title
  const NonGuestFolioTotal =
    (FolioDetails?.RoomCharges?.reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0) +
    (FolioDetails?.BillLedger?.reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0)

  var tourismTaxAmount = FolioDetails?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.TourismTax) {
      return x.TaxAmount
    } else {
      return 0
    }
  }).reduce((a, b) => a + b, 0)

  var Heritage = FolioDetails?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.HeritageTax) {
      return x.TaxAmount
    } else {
      return 0
    }
  })?.reduce((a, b) => a + b, 0)

  var Levy = FolioDetails?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.Levy) {
      return x.TaxAmount
    } else {
      return 0
    }
  }).reduce((a, b) => a + b, 0)
  var StateFund = FolioDetails?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.StateFund) {
      return x.TaxAmount
    } else {
      return 0
    }
  }).reduce((a, b) => a + b, 0)
  var TourismTax = FolioDetails?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.TourismTax) {
      return x.TaxAmount
    } else {
      return 0
    }
  })?.reduce((a, b) => a + b, 0)

  let PackagesPrice = 0
  var NumberCount = 1

  const iseInvoice = () => {
    const iseInvoice = JSON.parse(localStorage.getItem('Hotel'))?.Company?.isEInvIntegrated === true
    const startDate = JSON.parse(localStorage.getItem('Hotel'))?.Company?.eInvStartDate
    const endDate = JSON.parse(localStorage.getItem('Hotel'))?.Company?.eInvEndDate
    const effDate = FolioDetails?.FolioType === 'NonGuest' ? FolioDetails?.ClosedDate : FolioDetails?.FolioDate

    const eInvoice = iseInvoice && 
      (dateFormat(effDate, "yyyy-mm-dd") >= dateFormat(startDate, "yyyy-mm-dd")) &&
      (dateFormat(effDate, "yyyy-mm-dd") <= dateFormat(endDate, "yyyy-mm-dd"))

    return eInvoice === true ? true : false
  }

  const SelectedItem = [
    iseInvoice() === true
      ? FolioDetails?.BillLedger?.filter(
          x => x.IncidentalCharge?.IsOutlet !== true && x.IncidentalCharge?.IsEventInterface !== true && x.IncidentalCharge?.isEInvIntegrated === true
        )
      : FolioDetails?.BillLedger,
    FolioDetails?.RoomCharges,
    FolioDetails?.TaxLedger?.filter(x => x?.TaxType === TaxType.StateFund),
    isNonGuestFolio
      ? []
      : FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund'),
  ]
    ?.filter(Boolean)
    ?.flat(10) as any

  const [
    Template,
    {
      loading: Loadingtemp,
      data: { OutGoingDocTemplate } = {
        OutGoingDocTemplate: null,
      },
    },
  ] = useOutGoingDocTemplateLazyQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    Template()
  }, [HotelXFolio])

  const documentType = (Data) => {
    if (Data?.Folio?.DocumentType !== null) {
      return Data?.Folio?.DocumentType || Data?.DocumentType
    } else {
      return Data?.Folio?.FolioNo || Data?.FolioNo
    }
  }

  const isnegativeamount  = (Amount_:number,payment) =>{
    
    const converterbar = Number(Amount_) < 0 
         ?
       
          `(${amtStr((Amount_)*-1)})`
          :  `${amtStr((Amount_))}`
   
          const converterbar2 = Number(Amount_) < 0 

          return payment?.hasOwnProperty('PaymentType') && !converterbar2 ? `(${ converterbar})` : converterbar
     }
    const isinvoice = () => {
  
      if((FolioDetails?.DocumentType === null && FolioDetails?.FolioNo?.startsWith('INV')) ||
        (FolioDetails?.DocumentType !== null && FolioDetails?.DocumentType?.startsWith('INV'))
      ) {
        return true;
      } else if (
        (FolioDetails?.DocumentType === null &&
          (FolioDetails?.FolioNo?.startsWith('CN') || FolioDetails?.FolioNo?.startsWith('DN') || 
          FolioDetails?.FolioNo?.startsWith('OR') || FolioDetails?.FolioNo?.startsWith('RF'))) ||
        (FolioDetails?.DocumentType !== null &&
          (FolioDetails?.DocumentType?.startsWith('CN') || FolioDetails?.DocumentType?.startsWith('DN') || 
          FolioDetails?.DocumentType?.startsWith('OR') || FolioDetails?.DocumentType?.startsWith('RF')))
      ){
        return true;
      } else {
        return false
      }
    }
     const outstandingbalanceFunc = () =>{
      let result = ''
    
      if(iscityledgerdocs () === true || isinvoice() === false )
      {
        result = ''
      }
    
      else{
    result = outstandingbalancetemplate
      }
      return result
    }
  const isnonguestandsuspend = () => {
    return OutGoingDocTemplate !== null && stateInfo?.Isnonguestsuspend === true
      ? true
      : false
  }

  const selectedItemDesc = SelectedItem?.filter(z => z?.PaymentClass !== 'Refund')
  var desc = selectedItemDesc
    ?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))
    ?.map((x, i) => {
      NumberCount += 1
      return `
      
      <tr  
      class="room-detail" 
      style="
      font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;
      background-color: #fff;
      color:'#212121';
        font-size: 10px;
        line-height: 15px;
        text-align: center;">
    <td style={"text-align: center; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${dateFormat(
      x.TrxDate || x.PaymentDate,
      'dd/mm/yyyy'
    )}</td>
    <td style="text-align: left; line-height: 1.2;">${
      // console.log("x: ", x),
      !x.PaymentClass
        ? !x.IncidentalCharge
          ? !x.DepositClass
            ? x?.TrxDescription
              ? x.TrxDescription
              : x?.Description
              ? `${x?.Description} : ${
                  FolioDetails?.Registration
                    ? FolioDetails?.Registration?.Room?.RoomNo
                    : FolioDetails?.Booking?.Registration?.find(
                        y => y?.ID === x?.RegistrationID
                      )?.Room?.RoomNo || ''
                } : 
            ${
              FolioDetails?.Registration
                ? FolioDetails?.Registration?.Guest?.Contact?.FullName
                : FolioDetails?.Booking?.Registration?.find(
                    y => y?.ID === x?.RegistrationID
                  )?.Guest?.Contact?.FullName || ''
            }`
              : x.TaxType
              ? (x?.TaxDescription || x.TaxType)
              : x?.TransactionType
            : x.DepositClass
          : x.Description
          ? x.Description
          : x.IncidentalCharge.Description
        : x.PaymentClass === 'Refund'
        ? `Refund by ${x.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType}`
        : `${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
    } </td>
    ${
      OutGoingDocTemplate !== null
        ? `<td style="text-align: center;">${
            x.Qty !== undefined ? x.Qty : '1'
          }</td>`
        : `<td style="text-align: center;">
         
        </td>`
    }
    <td style={"font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${
      !x.ReferenceNo ? (!x?.CardNo ? '' : x?.CardNo) : x.ReferenceNo
    }</td>
    <td style="text-align: right; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none; padding-right: 2%">${isnegativeamount(
      Number(
      x.DepositAmount
        ? x.DepositAmount
        : PackagesPrice
        ? x.BaseAmount + PackagesPrice
        : x.BaseAmount
        ? x.BaseAmount
        : x.TaxAmount
        ? x.TaxAmount
        : x.Amount
    ),'' ) }</td>
    </tr>
    `
    })

    const taxListing = () => {
      let taxList: any = []
      let roomTaxGrouping: any = []
      let billTaxGrouping: any = []
  
      if (FolioDetails?.RoomCharges !== undefined && FolioDetails?.BillLedger !== undefined) {
  
        if (FolioDetails?.RoomCharges !== null && stateInfo?.FolioType !== 'NonGuest') {
          roomTaxGrouping = [].concat(
            ...FolioDetails?.RoomCharges?.map(x => {
              if (x?.TaxRoomLedger) {
                return x?.TaxRoomLedger
              } else {
                return
              }
            })?.filter(y => y !== undefined)
          )
        }
  
        if (FolioDetails?.RoomChargesSuspend !== null && stateInfo?.FolioType === 'NonGuest') {
          roomTaxGrouping = [].concat(
            ...FolioDetails?.RoomCharges?.map(x => {
              if (x?.TaxRoomLedger) {
                return x?.TaxRoomLedger
              } else {
                return
              }
            })?.filter(y => y !== undefined)
          )
        }
  
        if (FolioDetails?.BillLedger !== null) {
          billTaxGrouping = [].concat(
            ...FolioDetails?.BillLedger?.map(x => {
              if (x?.TaxBillLedger) {
                return x?.TaxBillLedger
              } else {
                return
              }
            })?.filter(y => y !== undefined)
          )
        }
  
        const Listing = roomTaxGrouping.concat(billTaxGrouping)
  
        taxList = Listing?.reduce((acc, curr) => {
          const { TaxID, TaxInfo, TaxAmount, TaxRate } = curr
  
          const existing = acc.find((x) => x.TaxID === TaxID)
  
          if (existing) {
            existing.TaxAmount += TaxAmount
          } else {
            acc.push({TaxID, TaxRate, TaxInfo, TaxAmount})
          }
  
          return acc;
        }, [])
      }
  
      return taxList
    }

    const { data } = useGetTaxScheme({
      TaxOutput: stateInfo?.Tax || taxListing(), 
      Type: 'NonGuest',
    })

    const HotelMayaTax = (stateInfo?.Tax || taxListing())?.map(x => {
      return `
      <tr
        class="" 
        style="background-color: #fff;
        color: #2c2c2c;
        font-size: 10px;
        line-height: 15px;"
      >
        <td
          style="font-weight: 300; padding-left: 6px;"
        >
          ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || x?.TaxRate}%)
        </td>
        <td
          style="font-weight: 300; item-align:right; text-align: right;"
        >
          ${isnegativeamount(Number(x?.TaxAmount || x?.taxAmount || x?.Value || 0.00), '')}
        </td>
      </tr>
    `
    })

    var docItem = Object.assign({}, HotelMayaTax)
    //Object to string
    var HotelMayaTaxItem = ''
    for (const [key, value] of Object.entries(docItem)) {
      HotelMayaTaxItem = HotelMayaTaxItem + value
    }

    const isRoundingnegativeamount  = (Amount_:number,payment, mode?) =>{
      let converterbar2 
      let converterbar 
  
      if(mode === 'CN') {
        converterbar2 = Number(Amount_) < 0 ? `(${amtStr((Amount_ * -1))})` : amtStr((Amount_ ))
        // converterbar2 = amtStr((Amount_ ))
      } else {
        converterbar =  Number(Amount_) < 0 ? amtStr((Amount_ * -1)) : `(${amtStr((Amount_))})`
      }
     
            
  
            return mode === 'CN' ? converterbar2 : converterbar
       }
  
  
    const iscityledgerdocs = () => {
      if(FolioDetails?.FolioNo !== null){
        if (FolioDetails?.DocumentType !== null){
          if((FolioDetails?.DocumentType?.startsWith('CN') ||  FolioDetails?.DocumentType?.startsWith('DN') 
              || FolioDetails?.DocumentType?.startsWith('OR') ||  
              FolioDetails?.DocumentType?.startsWith('RF') ) && OutGoingDocTemplate?.Folio !== null
            ){
              return true;
            } 
            else {
              return false
            }
        } else {
          if((FolioDetails?.FolioNo?.startsWith('CN') ||  FolioDetails?.FolioNo?.startsWith('DN') 
            || FolioDetails?.FolioNo?.startsWith('OR') ||  
            FolioDetails?.FolioNo?.startsWith('RF') ) && OutGoingDocTemplate?.Folio !== null )
            {
              return true;
            } 
            else {
              return false
            }
        }
      }
        else{
          return false
        }
      
      
        }
  let newitem2: string = ``

  let TotalAmount = isNonGuestFolio
  ? NonGuestFolioTotal !== undefined
    ? amtStr(NonGuestFolioTotal)
    : 0
  : FolioDetails === null
  ? amtStr(stateInfo?.Total)
  : stateInfo?.Page === 'PaymentFolio' &&
    SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
  ? amtStr(FolioDetails?.FolioAmount)
  : amtStr(FolioDetails?.FolioAmount)

  let totalpayment = (FolioDetails?.Payment?.filter(t => t.Amount > 0 && t.PaymentType !== "CityLedger").reduce((acc, curr) => {
    return acc + curr.Amount
  }, 0) || 0)

  if (FolioDetails?.Payment !== null) {
    FolioDetails?.Payment?.sort((a, b) =>
      a.ReceiptNo?.localeCompare(b.ReceiptNo)
    ).filter(x => OutGoingDocTemplate !== null
      ? x.DebtorAccount === null && x.PaymentType !== "CityLedger" : x).map((x, y) => {
      let item = `
        <tr  
        class="room-detail" 
        style="
          font-family: 'Poppins';
          -webkit-print-color-adjust: exact;
          -webkit-text-size-adjust:none;
          border: 0px solid #fff;
          background-color: #fff;
          color: #2c2c2c;
          font-size: 10px;
          line-height: 15px;
          text-align: center;">
          <td style="text-align: center; font-family:'Poppins';">${
            x
              ? dateFormat(x?.PaymentDate, 'dd/mm/yyyy')
              : dateFormat(AdvancePaymentDetails?.PaymentDate, 'dd/mm/yyyy')
          }</td>
          <td style="text-align: left; font-family:'Poppins';">${
            isNonGuestFolio
              ? `${x?.PaymentType} Payment`
              : x
              ? documentType(FolioDetails)?.slice(0, 2) === 'RF'
                ? `${x?.PaymentClass} - ${
                    DepositClass?.DepositClass === 'SecurityDeposit'
                      ? 'Security Deposit'
                      : 'Key Card Deposit'
                  }`
                : documentType(FolioDetails)?.slice(0, 1) === 'D' &&
                  documentType(FolioDetails)?.slice(0, 2) !== 'DN'
                ? FolioDetails?.DepositLedger &&
                  FolioDetails?.DepositLedger[0]?.DepositClass ===
                    'SecurityDeposit'
                  ? 'Security Deposit'
                  : 'Key Card Deposit'
                : x?.PaymentClass === 'Refund'
                ? `Refund by ${x?.PaymentType}`
                : x?.PaymentType === 'CityLedger'
                ? // ? `Charge to City Ledger12 - ${PaymentDetails?.Registration?.Booking?.DebtorAccount}`
                  `Charge to City Ledger - ${x?.DebtorAccount}`
                : `Advance Payment -${x?.PaymentType}`
              : AdvancePaymentDetails?.Booking?.DebtorAccount === null || AdvancePaymentDetails?.PaymentType !== 'CityLedger'
              ? `${AdvancePaymentDetails?.PaymentType} Payment`
              : `Charge to City Ledger - ${AdvancePaymentDetails?.Booking?.DebtorAccount}`
          }</td>
          <td style="text-align: center;   font-family: 'Poppins';">
          </td>
   
          <td style="text-align: left;  font-family: 'Poppins'; padding-left:0px;">${x ? x?.ReceiptNo : ''}</td>
     
          <td style="text-align: right; padding-right: 2%;   font-family: 'Poppins';">
          ${
          x ? isnegativeamount(Number(x?.Amount),'payment')
          :isnegativeamount(AdvancePaymentDetails?.Amount,'payment')
          }</td>
    </tr>`

      newitem2 = `` + newitem2.concat(item)
    })
  }
  console.log(newitem2, 'newitem2')
  console.log(stateInfo, 'stateInfo')

  const subAmount = (SelectedItem?.reduce((acc, curr) => {
    return (curr.BaseAmount || curr.TaxAmount) 
      ? acc + (curr.BaseAmount || curr.TaxAmount) 
      : acc + 0
  }, 0) || 0)

  const roundingAmount = FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')
    ?.map(x => x?.Amount || null)
    ?.reduce((a, b) => (a + b), 0)

  //  +(stateInfo?.Folio?.reduce((acc, curr) => {
  //   return acc + curr.TrxAmount
  // }, 0) || 0) 
console.log(Number(TotalAmount) ,Number(totalpayment),'test')

const outstandingbalancetemplate = `	  <div style="width: 100%; display: flex; margin-top: 10px;">
                        
<div style="width: 50%;  margin: 4px; padding: 6px; ">

</div>

<div style="width: 50%; display: block; padding-left: 15%;padding-right: 6px;">
       
       <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 10px; font-family: 'Poppins';
   -webkit-print-color-adjust: exact;
   -webkit-text-size-adjust:none;" >

<tr class="" style="      background-color: #fff;
           color: #2c2c2c;
           font-size: 10px;
           line-height: 15px;
           ;
           ">
               <td style="
               font-weight: 800; padding-left: 0px; font-family:'Poppins';">OUTSTANDING BALANCE</td>
               <td style="
               font-weight: 800; font-family:'Poppins';  item-align:right; text-align: right; padding-left: 5px;
               color:#2c2c2c
               ">${(isnegativeamount(Number(TotalAmount) - Number(totalpayment),''))}</td>
<td style="width:4%;
               "></td>
           </tr>
       </table>
</div>

</div>`
//maya only <3
const agentname = () =>{
  let result  = ''
  
  if(OutGoingDocTemplate !== null && DebtorFolioInfo !== null){
  
      result = `Bill To :`
 
  }
  else{
    result = !FolioDetails?.Registration?.Booking?.Debtor?.DebtorAccount
      ? ''
      : `Company/Travelling Agent Name:${FolioDetails?.Registration?.Booking?.Debtor?.Name}` 
  }
      
      return result;
        
    }
  //Arr to Object
  var item = Object.assign({}, desc)
  //Object to string
  var EachFolioItem = ''
  for (const [key, value] of Object.entries(item)) {
    EachFolioItem = EachFolioItem + value
  }

  const isSelectedItemGovTax = SelectedItem?.some(
    x => x?.TaxType === TaxType.GovTax
  )

  
  const conditionSub = subAmount === 0 ? FolioDetails?.FolioAmount : subAmount

  const isDocType = (type) => {
    const docType = (FolioDetails?.DocumentType === null && FolioDetails?.FolioNo?.startsWith(type)) ||
      (FolioDetails?.DocumentType !== null && FolioDetails?.DocumentType?.startsWith(type))
    
    return docType
  }

  const eInvoiceQr = () => {
    const condition = FolioDetails?.is_einvoice === true && 
      HotelDetails?.Company?.isEInvIntegrated === true &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') >= dateFormat(HotelDetails?.Company?.eInvStartDate, 'yyyy-mm-dd') &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') <= dateFormat(HotelDetails?.Company?.eInvEndDate, 'yyyy-mm-dd') &&
      FolioDetails?.QrCodeUrl !== null

    const result = condition === true 
      ? `<td class="qr-code" rowspan="5"; style="width:100%; text-align:right; justify-content:end; position: relative; vertical-align: center">
          <span style="display: flex; justify-content: center; text-align: center; padding-bottom: 4px">
            <img 
              src="${FolioDetails?.QrCodeUrl}"
              alt="QR Code" 
              style="width: 85px; display: inline-block;"
            >
          </span>
          <div style="width: 100%; right: 0; line-height: 1">
            <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
              Scan within 72 hours if
            </span>
            <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
              e-Invoice is required
            </span>
          </div>
        </td>`
      :``
    return result;
  }

  /// Disbursement & Reimbursement Item
  //* start */
  const SelectedItemEO = FolioDetails?.BillLedger?.filter(
    x => x.IncidentalCharge?.IsOutlet === true || 
      x.IncidentalCharge?.IsEventInterface === true || 
      x.IncidentalCharge?.isEInvIntegrated !== true
  )

  const subTotalEO = SelectedItemEO?.reduce((acc, curr) => {
    return acc + curr?.BaseAmount
  }, 0)

  const TotalEO = SelectedItemEO?.reduce((acc, curr) => {
    return acc + curr?.TrxAmount
  }, 0) - Number(SelectedItemEO?.length > 0 ? roundingAmount : 0)
  const GrandTotal = Number(TotalAmount)

  var descEO = SelectedItemEO?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))?.map(x => {
    return `
      <tr class="room-detail" 
        style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; 
        -webkit-text-size-adjust:none; border: 2px solid #fff; background-color: #fff; 
        color: #212121; font-size: 10px; text-align: center;"
      >
        <td style="text-align: center; font-family: 'Poppins';">
          ${dateFormat(x.TrxDate, 'dd/mm/yyyy')}
        </td>
        <td style="text-align: left; line-height: 1.2; font-family: 'Poppins';">
          ${!x.IncidentalCharge ? x.Description : x.IncidentalCharge.Description} 
        </td>
        ${OutGoingDocTemplate !== null
          ? `<td style="text-align: center; font-family:'Poppins';">
              ${x.Qty !== undefined ? x.Qty : '1'}
            </td>`
          : `<td style="text-align: center; font-family:'Poppins';">
              ${''}
            </td>`
        }
        <td style="text-align: left; line-height: 1.2; font-family: 'Poppins'; border: 2px solid #fff;">
          ${!x.ReferenceNo ? '' : x.ReferenceNo}
        </td>
        <td style="text-align: right; line-height: 1.2; padding-right: 2%; font-family: 'Poppins';">
          ${isnegativeamount(Number(x.BaseAmount), x)}
        </td>
      </tr>
    `
  })

  var itemEO = Object.assign({}, descEO)
  var EachFolioItemEO = ''
  for (const [key, value] of Object.entries(itemEO)) {
    EachFolioItemEO = EachFolioItemEO + value
  }

  const taxListingEO = () => {
    let taxList: any = []
    let billTaxGrouping: any = []
    var EachTaxItemEO = ''

    if (FolioDetails?.RoomCharges !== undefined && FolioDetails?.BillLedger !== undefined) {
      if (FolioDetails?.BillLedger !== null) {
        billTaxGrouping = [].concat(
          ...FolioDetails?.BillLedger?.filter(
            f => f.IncidentalCharge?.isEInvIntegrated !== true ||
            f.IncidentalCharge?.IsOutlet === true || 
            f.IncidentalCharge?.IsEventInterface === true
          )?.map(x => {
            if (x?.TaxBillLedger) {
              return x?.TaxBillLedger
            } else {
              return
            }
          })?.filter(y => y !== undefined)
        )
      }

      const Listing = billTaxGrouping

      taxList = Listing?.reduce((acc, curr) => {
        const { TaxID, TaxInfo, TaxAmount, TaxRate } = curr

        const existing = acc.find((x) => x.TaxID === TaxID)

        if (existing) {
          existing.TaxAmount += TaxAmount
        } else {
          acc.push({TaxID, TaxRate, TaxInfo, TaxAmount})
        }

        return acc;
      }, [])
    }

    if (taxList?.length > 0) {
      const taxListArr = taxList?.map((x) => {
        return `
          <tr
            class="" 
            style="font-family: 'Poppins';
            -webkit-print-color-adjust: exact;
            -webkit-text-size-adjust:none;
            background-color: #fff;
            color: #2c2c2c;
            font-size: 10px;
            line-height: 15px;"
          >
            <td
              style="font-weight: 300; padding-left: 6px;"
            >
              ${x?.TaxInfo?.code} (${x?.TaxRate}%)
            </td>
            <td
              style="font-weight: 300; item-align:right; text-align: right;"
            >
              ${isnegativeamount(Number(x?.TaxAmount || 0.00), '')} 
            </td>
          </tr>
        `
      });

      var docItem = Object.assign({}, taxListArr)
      //Object to string
      for (const [key, value] of Object.entries(docItem)) {
        EachTaxItemEO = EachTaxItemEO + value
      }
    }

    return taxList?.length > 0 ? EachTaxItemEO : ''
  }

  const externalOutlet = SelectedItemEO?.length > 0 
    ? `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding-bottom: 10px">
        <tr style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none; 
          background-color: #FFFFFF; color: #FFFFFF; font-size: 10px; line-height: 28px; text-align: center;"
        >
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:160px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:315px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:30px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:160px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:120px;"></th>
        </tr>
        <tr style="background-color: #fff; color: #2c2c2c; font-size: 10px; line-height: 15px;">
          <td colspan="4" style="font-weight: 800; text-decoration: underline; padding-bottom: 2px; padding-left: 6%">
            Disbursement/Reimbursement
          </td>
        </tr>
        ${EachFolioItemEO}
      </table>
      <div style="width: 100%; display: flex; margin-top: 5px;">           
        <div style="width: 50%;  margin: 4px; padding: 6px; "></div>
        <div style="width: 50%; display: block; padding-left: 25%; padding-right: 3px;">    
          <table 
            width="100%" border="0" cellspacing="0" cellpadding="0"  
            style="margin-bottom: 12px; font-family: 'Poppins';
            -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;" 
          >
            <tr 
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td style="font-weight: 300; padding-left: 6px;">
                Sub Total
              </td>
              <td style="font-weight: 300; item-align:right; text-align: right;">
                ${isnegativeamount(Number(subTotalEO.toFixed(2)), '')}
              </td>
              <td style="width:4%;"></td>
            </tr>
            ${taxListingEO()}
            <tr 
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td style="font-weight: 300; padding-left: 6px;">
                Rounding
              </td>
              <td style="font-weight: 300;  item-align:right;text-align: right;">
                ${isRoundingnegativeamount(Number(amtStr(FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')?.map(x => x?.Amount || null)
                .reduce((a, b) => (a + b), 0))), '', documentType(FolioDetails)?.includes('CN') ? 'CN' : '')  || amtStr(0)}
              </td>
            </tr>
            <tr 
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td style="font-weight: 800; padding-left: 6px;">
                TOTAL
              </td>
              <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c">
                ${isnegativeamount(Number(TotalEO.toFixed(2)), '')}
              </td>
              <td style="width:4%;"></td>
            </tr>
          </table>
        </div>              
      </div>
      <div style="width: 100%; display: flex; padding-bottom: 10px;">
        <div style="width: 50%; margin: 4px; padding: 6px; "></div>
        <div style="width: 50%; display: block; padding-left: 25%; padding-right: 3px; ">
          <table width="100%" border="0" cellspacing="0" cellpadding="0" 
            style="margin-bottom: 12px; font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;" 
          >
            <tr class=" " style="background-color: #fff; color: #2c2c2c; font-size: 10px; line-height: 1px;">
              <td style="font-weight: 800; padding-left: 6px;">
                GRAND TOTAL
              </td>
              <td style="font-weight: 800; item-align:right; text-align: right; color:#2c2c2c">
                ${isnegativeamount(Number(GrandTotal.toFixed(2)), '')}
              </td>
              <td style="width:4%; "></td>
            </tr>
          </table>
        </div>
      </div>`
    : ``
  //* end */

  const amountTotal = (SelectedItem?.reduce((acc, curr) => {
    return (curr.TrxAmount || curr.TaxAmount) 
      ? acc + Number(curr.TrxAmount || curr.TaxAmount || 0)
      : acc + 0
  }, 0) || 0) + Number(TourismTax || 0) + Number(Heritage || 0) + Number(Levy || 0) - (SelectedItemEO?.length > 0 ? 0 : roundingAmount)

  const TotalRounding = (iseInvoice() === true && SelectedItemEO?.length > 0) 
    ? '' 
    :`<td style="font-weight: 300; padding-left: 6px;">
        Rounding
      </td>
      <td style="font-weight: 300;  item-align:right;text-align: right;">
        ${isRoundingnegativeamount(Number(amtStr(FolioDetails?.Payment
          ?.filter(z => z?.PaymentClass === 'Rounding')
          ?.map(x => x?.Amount || null)
          ?.reduce((a, b) => (a + b), 0))), '', 
          documentType(FolioDetails)?.includes('CN') ? 'CN' : '')  || amtStr(0)}
      </td>`
    
  const TourismTaxType = TourismTax > 0
    ? `<td style="font-weight: 300; padding-left: 0px;">
        Tourism Tax
       </td>
       <td style="font-weight: 300;  item-align:right;text-align: center;">
        ${amtStr(TourismTax)}
       </td>`
    : ''

  const TaxTypeInput = type === 'REFUND' || documentType(FolioDetails)?.slice(0, 1) === 'D'
    ? ''
    : SelectedItem?.length > 0
      ? stateInfo?.Page === 'PaymentFolio'
        ? !FolioDetails?.TaxLedger
          ? ''
          : Heritage === 0 && Levy === 0 && StateFund === 0
            ? ''
            : Heritage > 0
              ? `<td style="font-weight: 300; padding-left: 0px;">
                    Heritage Tax
                 </td>
                 <td style="font-weight: 300;  item-align:right;text-align: center;">
                    ${amtStr(Heritage)}
                 </td>`
              : Levy > 0
                ? `<td style="font-weight: 300; padding-left: 0px;">
                      Levy Tax
                   </td>
                   <td style="font-weight: 300; item-align:right; text-align: center;">
                      ${amtStr(Levy)}
                   </td>`
                : ''
        : ''
      : ''

  const AmountItem = `
    <div style="width: 100%; display: flex; margin-top: 5px;">                 
      <div style="width: 50%;  margin: 4px; padding: 6px; "></div>
      <div style="width: 50%; display: block; padding-left: 25%;padding-right: 3px;">  
        <table 
          width="100%" border="0" cellspacing="0" cellpadding="0"  
          style="margin-bottom: 12px; font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;" 
        >
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            <td style="font-weight: 300; padding-left: 6px;">
              Sub Total
            </td>
            <td style=" font-weight: 300; item-align:right; text-align: right;">
              ${isnegativeamount(conditionSub,'')}
            </td>
            <td style="width:4%;"></td>
          </tr>

          ${OutGoingDocTemplate?.Folio ? HotelMayaTaxItem || '' : data || ''}

          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${TotalRounding}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${TourismTaxType}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
            color: #2c2c2c;
            font-size: 10px;
            line-height: 15px;"
          >
            ${TaxTypeInput}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
              <td style="font-weight: 800; padding-left: 6px;">
                TOTAL
              </td>
              <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c">
                ${isnegativeamount((Number(amountTotal)),'')}
              </td>
              <td style="width:4%;"></td>
          </tr>
        </table>
      </div>
    </div>`

  const eInvoiceUniqueID = () => {
    const isCondition = FolioDetails?.is_einvoice === true && 
      HotelDetails?.Company?.isEInvIntegrated === true &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') >= dateFormat(HotelDetails?.Company?.eInvStartDate, 'yyyy-mm-dd') &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') <= dateFormat(HotelDetails?.Company?.eInvEndDate, 'yyyy-mm-dd')
    
    const resUniqueID = isCondition
        ? 'UUID : ' + (FolioDetails?.einvoice_uniqueID !== '' ? FolioDetails?.einvoice_uniqueID : '-')
        : ''
    return resUniqueID
  }

  var mapObj: any = {
    //Hotel
    '!!HotelName!!':` ${HotelDetails?.Company?.name} (${HotelDetails?.Company?.companyRegNo})`,
    '!!CompanyName!!': HotelDetails?.Company?.['name'] || '',
    '!!HotelImage!!': !JSON.parse(localStorage.getItem('Hotel'))?.LogoImage
      ? 'https://hotelx-dev.ifca.io/document/ifca.png'
      : JSON.parse(localStorage.getItem('Hotel'))?.LogoImage.ImageURL,
    '!!ImperialLogo!!': ImperialLogo,
    '!!HotelEmail!!': !HotelDetails?.['Contact']
      ? ''
      : !HotelDetails?.['Contact']?.['BusinessEmail']
      ? ''
      : HotelDetails?.['Contact']?.['BusinessEmail'],
    '!!SSTRegNo!!': !HotelDetails?.Company?.['sstNo'] || HotelDetails?.Company?.['sstNo'] === 'NA' ? '' : '(SST Reg No: ' + HotelDetails?.Company['sstNo'] + ')',
    '!!TTXRegNo!!': !HotelDetails?.Company?.['ttxNo'] || HotelDetails?.Company?.['ttxNo'] === 'NA' ? '' : '(TTX Reg No: ' + HotelDetails?.Company['ttxNo'] + ')',
    '!!TINNo!!':
      !HotelDetails?.Company?.['tin'] || !HotelDetails?.Company?.['isEInvIntegrated']
        ? ''
        : differenceInDays(
            new Date(dateFormat(FolioDetails?.ClosedDate || FolioDetails?.FolioDate, 'yyyy-mm-dd')),
            new Date(dateFormat(HotelDetails?.Company?.['eInvStartDate'], 'yyyy-mm-dd'))
          ) < 0
          ? ''
          : '(TIN No: ' + HotelDetails?.Company['tin'] + ')',
    '!!HotelMobileNo!!': !HotelDetails?.['Contact']?.['PhoneNo']
      ? ''
      : HotelDetails?.['Contact']?.['PhoneNo'],
    '!!HotelFaxNo!!': HotelDetails?.Contact?.FaxNo || '',
    '!!HotelPhone!!': HotelDetails?.Contact?.MobileNo || '',
    '!!HotelCustomAddress!!':
      ` <p style="color:#2c2c2c">
      ${HotelDetails?.['HotelAddress']?.['address']
        .concat(' ')
        .concat(HotelDetails?.['HotelAddress']?.['postCode'])}
      <br> ${HotelDetails?.['HotelAddress']?.['state']}${' '}${
        HotelDetails?.['HotelAddress']?.['country']
      }</p>` || '',
    '!!HotelWebsite!!': !HotelDetails?.['Contact']?.['WebUrl']
      ? ''
      : HotelDetails?.['Contact']?.['WebUrl'],
    '!!HotelAddress!!': HotelDetails?.['HotelAddress']
      ? AddressConverter({
          address: HotelDetails?.['HotelAddress']?.['address'],
          state: HotelDetails?.['HotelAddress']?.['state'],
          postCode: HotelDetails?.['HotelAddress']?.['postCode'],
          city: HotelDetails?.['HotelAddress']?.['city'],
          country: HotelDetails?.['HotelAddress']?.['country'],
        })
      : '',

    '!!FolioNo!!': FolioDetails?.FolioNo || '',

    '!!FOLIO!!':
      stateInfo?.Page === 'PaymentFolio'
        ? isDocType('CN')
          ? 'CREDIT NOTE'
          : isDocType('DN')
          ? 'DEBIT NOTE'
          : stateInfo?.Page === 'ConfirmationFolio'
          ? 'FOLIO'
          : 'INVOICE'
        : 'INVOICE',
    //Header top
    '!!CashierName!!':
      BIUsersLisiting?.name ||
      JSON.parse(localStorage.getItem('LoggedInUser'))?.name,
    '!!Date!!': dateFormat(FolioDetails?.FolioDate, 'dd/mm/yyyy'),
    '!!RoomNo!!':
      stateInfo?.Folio?.FolioType === 'Group Folio' ||
      FolioDetails?.FolioType === 'GroupFolio'
        ? ''
        : FolioDetails?.Registration?.Room?.RoomNo ||
          FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)?.map(
            y => y?.Room?.RoomNo
          ) ||
          '',
    '!!BeneficiaryName!!': FolioDetails?.BeneficiaryName || '',

    '!!AgentName!!': agentname(),

    '!!GuestName!!': isNonGuestFolio
      ? NonGuestFolioTitle
      : stateInfo?.Folio?.FolioType === 'Group Folio' ||
        FolioDetails?.FolioType === 'GroupFolio'
      ? stateInfo?.PersonalInfo?.booking?.GroupName ||
        FolioDetails?.Booking?.GroupName ||
        ((FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)[0]
        ?.Guest?.Contact?.Salutation || "") + 
        " " + 
        (FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)[0]
          ?.Guest?.Contact?.FullName || ""))
      : (FolioDetails?.Registration?.Guest?.Contact?.Salutation||
        FolioDetails?.Booking?.Contact?.Salutation|| "") + 
        " " +
        (FolioDetails?.Registration?.Guest?.Contact?.FullName ||
        FolioDetails?.Booking?.Contact?.FullName ||
        ''),

    '!!GuestMobileNo!!': FolioDetails?.Registration
      ? FolioDetails?.Registration?.Guest?.Contact?.MobileNo || ''
      : FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.MobileNo || '',

    '!!GuestEmail!!': FolioDetails?.Registration
      ? FolioDetails?.Registration?.Guest?.Contact?.Email || ''
      : FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Email || '',

    '!!GuestAddress!!':
      FolioDetails?.BeneficiaryAddress ||
      AddressConverter({
        address: FolioDetails?.Registration?.Guest?.Contact?.Address?.address,
        address2: FolioDetails?.Registration?.Guest?.Contact?.Address?.address2,
        city: FolioDetails?.Registration?.Guest?.Contact?.Address?.city,
        country: FolioDetails?.Registration?.Guest?.Contact?.Address?.country,
        postCode: FolioDetails?.Registration?.Guest?.Contact?.Address?.postCode,
        state: FolioDetails?.Registration?.Guest?.Contact?.Address?.state,
      }) ||
      AddressConverter({
        address:
          FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
            ?.address,
        address2:
          FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
            ?.address2,
        city: FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
          ?.city,
        country:
          FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
            ?.country,
        postCode:
          FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
            ?.postCode,
        state:
          FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
            ?.state,
      }),

    '!!IdentityCard!!': FolioDetails?.Registration
      ? FolioDetails?.Registration?.Guest?.Contact?.NRIC ||
        FolioDetails?.Registration?.Guest?.Contact?.PassportNo ||
        ''
      : FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.NRIC ||
        FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.PassportNo ||
        '',

    '!!ArrivalDate!!':
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails?.Registration
        ? dateFormat(FolioDetails?.Registration?.ArrivalDate, 'dd/mm/yyyy')
        : dateFormat(
            FolioDetails?.Booking?.Registration[0]?.ArrivalDate,
            'dd/mm/yyyy'
          ),

    '!!ArrivalTime!!':
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails?.Registration
        ? !FolioDetails?.Registration?.CheckInDate
          ? dateFormat(FolioDetails?.Registration?.ArrivalDate, 'hh:MM:ss TT')
          : dateFormat(FolioDetails?.Registration?.CheckInDate, 'hh:MM:ss TT')
        : !FolioDetails?.Booking?.Registration[0]?.CheckInDate
        ? dateFormat(
            FolioDetails?.Booking?.Registration[0]?.ArrivalDate,
            'hh:MM:ss TT'
          )
        : dateFormat(
            FolioDetails?.Booking?.Registration[0]?.CheckInDate,
            'hh:MM:ss TT'
          ),

    '!!DepartureDate!!':
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails?.Registration
        ? !FolioDetails?.Registration?.CheckOutDate
          ? dateFormat(FolioDetails?.Registration?.DepartureDate, 'dd/mm/yyyy')
          : dateFormat(FolioDetails?.Registration?.CheckOutDate, 'dd/mm/yyyy')
        : !FolioDetails?.Booking?.Registration[0]?.CheckOutDate
        ? dateFormat(
            FolioDetails?.Booking?.Registration[0]?.DepartureDate,
            'dd/mm/yyyy'
          )
        : dateFormat(
            FolioDetails?.Booking?.Registration[0]?.CheckOutDate,
            'dd/mm/yyyy'
          ),

    '!!DepartureTime!!':
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails?.Registration
        ? !FolioDetails?.Registration?.CheckOutDate
          ? dateFormat(FolioDetails?.Registration?.DepartureDate, 'hh:MM:ss TT')
          : dateFormat(FolioDetails?.Registration?.CheckOutDate, 'hh:MM:ss TT')
        : !FolioDetails?.Booking?.Registration[0]?.CheckOutDate
        ? dateFormat(
            FolioDetails?.Booking?.Registration[0]?.DepartureDate,
            'hh:MM:ss TT'
          )
        : dateFormat(
            FolioDetails?.Booking?.Registration[0]?.CheckOutDate,
            'hh:MM:ss TT'
          ),

    '!!BookingNo!!':
      FolioDetails?.Registration?.Booking?.BookingNo ||
      FolioDetails?.Booking?.BookingNo ||
      '',
    //description
    '!!SubTotal!!' : amtStr(subAmount),
    '!!TaxSchemeItem!!' : OutGoingDocTemplate?.Folio ? HotelMayaTaxItem || '' : data || '',
    '!!TotalRounding!!' : `<td style="
    font-weight: 300; padding-left: 6px;">Rounding</td>
       <td style="
       font-weight: 300;  item-align:right;text-align: right;">${isRoundingnegativeamount(Number(amtStr(FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')?.map(x => x?.Amount || null)
       .reduce((a, b) => (a + b), 0))), '', documentType(FolioDetails)?.includes('CN') ? 'CN' : '')  || amtStr(0)}</td>`,
    '!!NewItem!!':
      FolioDetails !== null
        ? !FolioDetails?.RoomLedger && !FolioDetails?.BillLedger
          ? FolioDetails?.DepositLedger
            ? FolioDetails?.DepositLedger?.map((x, i) => {
                NumberCount += 1
                //  console.log(NumberCount)
                return `
        
        <tr  
        class="room-detail" 
        style="
        font-family: 'Poppins';
        -webkit-print-color-adjust: exact;
        -webkit-text-size-adjust:none;
        border: 2px solid #fff;
        color:'#212121';
          font-size: 10px;
          line-height: 15px;
          text-align: center;">
      <td style={"text-align: center; font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;"}>${dateFormat(x.TrxDate, 'dd/mm/yyyy')}</td>
      <td style="text-align: left; line-height: 1.2;">
      ${`${x?.DepositClass}`} </td>
      <td style={"font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;"}>${x?.ReferenceNo ? x?.ReferenceNo : ''}</td>
      <td style={"text-align: center;font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;"}>${amtStr(x?.DepositAmount)}</td>
      </tr>
      `
              })
            : FolioDetails?.TaxLedger
            ? FolioDetails?.TaxLedger?.map((x, i) => {
                NumberCount += 1
                //  console.log(NumberCount)
                return `
      
      <tr  
      class="room-detail" 
      style="
      font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;
      background-color:'#212121';
      color:'#212121';
        font-size: 10px;
        line-height: 15px;
        text-align: center;">
    <td style={"text-align: center; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${dateFormat(x.TrxDate, 'dd/mm/yyyy')}</td>
    <td style="text-align: left; line-height: 1.2;">
    ${`${x?.TaxDescription || x?.TaxType}`} </td>
    <td style={"font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${''}</td>
    <td style={"font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${''}</td>
    <td style={"text-align: center;font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${amtStr(x?.TaxAmount)}</td>
    </tr>
    `
              })
            : FolioDetails?.Payment?.map((x, i) => {
                NumberCount += 1
                //  console.log(NumberCount)
                return `
    
    <tr  
    class="room-detail" 
    style="
    font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;
    background-color:'#212121';
    color:'#212121';
      font-size: 10px;
      line-height: 15px;
      text-align: center;">
  <td style={"text-align: center; font-family: 'Poppins';
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust:none;
  border: 2px solid #fff;"}>${dateFormat(x.PaymentDate, 'dd/mm/yyyy')}</td>
  <td style="text-align: left; line-height: 1.2;">
  ${`${
    x.PaymentClass === 'Refund'
      ? `Refund by ${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType}`
      : `${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
  }`} </td>
  <td style={"font-family: 'Poppins';
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust:none;
  border: 2px solid #fff;"}>${''}</td>
  <td style={"text-align: center;font-family: 'Poppins';
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust:none;
  border: 2px solid #fff;"}>${amtStr(x?.Amount)}</td>
  </tr>
  `
              })
          : EachFolioItem
        : EachFolioItem,
    '!!ServiceCharge!!': GetServiceChargeTax['Value'],
    '!!TotalServiceCharge!!':
      stateInfo?.Page === 'PaymentFolio'
        ? amtStr(
            SelectedItem?.map(x => x?.ServiceCharge || null).reduce(
              (a, b) => a + b,
              0
            )
          )
        : amtStr(0),
    '!!Tax!!': GetCurrentGovTax['Value'],
    '!!TotalGovTax!!':
      stateInfo?.Page === 'PaymentFolio'
        ? isNonGuestFolio
          ? amtStr(
              SelectedItem?.map(x => x?.TaxAmount || null).reduce(
                (a, b) => a + b,
                0
              )
            )
          : isSelectedItemGovTax
          ? amtStr(
              SelectedItem?.filter(x => x.TaxType !== 'StateFund')
                ?.filter(x =>
                  x?.TaxType ? x?.TaxType === TaxType.GovTax : null
                )
                ?.map(x => x?.TaxAmount || null)
                .reduce((a, b) => a + b, 0)
            )
          : amtStr(
              SelectedItem?.filter(x => x.TaxType !== 'StateFund')
                ?.map(x => {
                  if (x?.TaxAmount) return x?.TaxAmount
                  return 0
                })
                .reduce((a, b) => a + b, 0)
              // SelectedItem?.map(x => x?.TaxAmount || null).reduce(
              //   (a, b) => a + b,
              //   0
              // )
            )
        : amtStr(0),
    '!!TotalTourismTax!!':
      stateInfo?.Page === 'PaymentFolio' ? amtStr(tourismTaxAmount) : amtStr(0),
    '!!TaxType!!':
      type === 'REFUND' || documentType(FolioDetails)?.slice(0, 1) === 'D'
        ? ''
        : SelectedItem?.length > 0
        ? stateInfo?.Page === 'PaymentFolio'
          ? !FolioDetails?.TaxLedger
            ? ''
            : Heritage === 0 && Levy === 0 && StateFund === 0
            ? ''
            : Heritage > 0
            ? `<td style="
font-weight: 300; padding-left: 0px;">Heritage Tax</td>
   <td style="
   font-weight: 300;  item-align:right;text-align: center;">${amtStr(
     Heritage
   )}</td>
`
            : Levy > 0
            ? `<td style="
font-weight: 300; padding-left: 0px;">Levy Tax</td>
   <td style="
   font-weight: 300; item-align:right; text-align: center;">${amtStr(Levy)}</td>
`
            : ''
          : ''
        : '',

    '!!TourismTaxType!!':
      TourismTax > 0
        ? `<td style="
    font-weight: 300; padding-left: 0px;">Tourism Tax</td>
       <td style="
       font-weight: 300;  item-align:right;text-align: center;">${amtStr(
         TourismTax
       )}</td>`
        : '',

    '!!TaxTypeAmount!!': amtStr(
      FolioDetails?.TaxLedger?.map(x => x?.TaxAmount).reduce((a, b) => a + b, 0)
    ),

    '!!TotalAmount!!': isNonGuestFolio
      ? NonGuestFolioTotal !== undefined
        ? amtStr(NonGuestFolioTotal)
        : 0
      : FolioDetails === null
      ? amtStr(stateInfo?.Total)
      : stateInfo?.Page === 'PaymentFolio'
      ? amtStr(FolioDetails?.FolioAmount) : amtStr(0),
      '!!QtyHeader!!':`<th style="font-weight: 500;  width:10%;">Qty</th>`,
      '!!BankDetail!!' : isnonguestandsuspend() === true ? `
      <div style="font-size: 10px; padding-top:25px; padding-bottom:50px;  ">
    <table border="1" cellspacing="0" cellpadding="0" style=";width: 40%;text-alignment:center; margin-left: auto;
                                    margin-right: auto;">
      <tr>
        <td>
          <table border="0" cellpadding=0 cellspacing=0>
            <tr>
              <td colspan="3" style="padding-left:15px;">Our bank details as below :</td>
            </tr>
            <tr>
              <td style="width:5%; padding-left:15px;">Beneficiary</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;">SDB Properties Sdn Bhd</td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Bank Name</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;">Public Bank Berhad</td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Bank Address</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;">Menara Public Bank </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;"></td>
              <td style="width:5px;"></td>
              <td style="width:12%;"> 146, Jalan Ampang </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;"></td>
              <td style="width:5px;"></td>
              <td style="width:12%;"> 50450 Kuala Lumpur,Malaysia </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Account No.</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;"> 310-3935-218 </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Bank ID</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;"> 190055-A </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Swift Code</td>
              <td style="width:2%;">:</td>
              <td style="width:12%;"> PBBEMYKL </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
      ` : ``,
  '!!IsSmfooter!!': isnonguestandsuspend() === true ? `` : 
  `    	
  <hr style="padding-right:10px;" />
  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding-top:10px; padding-bottom:10px;">
  <td style="font-size:10px; font-weight: 400;   line-height: 15px; width:25%; text-align: justify; color:#2c2c2c"> * I agree that <span style="font-weight: bold;">i</span> am personally liable for the payment of the following statement and if the person, company or association indicated by me as being responsible for payment of the same does not do so, that my liability for such payment shall be joint and several with such person, company or association. </td>
  <td style="font-size:10px; font-weight: 400;  line-height: 15px; width:20%; text-align: right;"></td>
  <td style="font-size:10px; font-weight: 400;   line-height: 15px; width:25%; text-align: left; color:#2c2c2c">
  <hr style="padding-right:10px;"> Guest Signature
  </td>
  </tr>
  </table>`,
  '!!customheightfooter!!': isnonguestandsuspend() === true ? `10%` : `20%`, //beta
  '!!customheightcontent!!': isnonguestandsuspend() === true ? `110px` : `220px`,
   '!!ICtd!!' : DebtorFolioInfo === null ? `  <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:50%;">
         IC &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ${     FolioDetails ? 
          OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' 
          :
         
              FolioDetails?.Registration?.Guest?.Contact?.NRIC ||
                FolioDetails?.Registration?.Guest?.Contact?.PassportNo  || ''
      
                : FolioDetailsWithIDs?.Guest?.Contact?.NRIC ||
               FolioDetailsWithIDs?.Guest?.Contact?.PassportNo  || ''
          } </td>` : '',
         '!!phonetd!!' :  DebtorFolioInfo === null  ? `  <td style="font-size:10px; font-weight: 400;  line-height: 18px; width:50%;">
         Phone : ${     FolioDetails ? 
          OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' :
           FolioDetails?.Registration?.Guest?.Contact?.MobileNo 
         : FolioDetailsWithIDs?.Guest?.Contact?.MobileNo }
        </td>`: '',
  '!!phoneImperialtd!!' :  DebtorFolioInfo === null  ? `  <td style="font-size:10px; font-weight: 400;  line-height: 18px; width:30%;">
    <div>${eInvoiceUniqueID()}</div>
    <div>
    Phone : ${     FolioDetails ? 
      OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' :
        FolioDetails?.Registration?.Guest?.Contact?.MobileNo 
        : FolioDetailsWithIDs?.Guest?.Contact?.MobileNo }
    </div>
    </td>`
    : `  <td style="font-size:10px; font-weight: 400;  line-height: 18px; width:30%;">
    <div>${eInvoiceUniqueID()}</div>
    </td>`,
         '!!emailtd!!' : DebtorFolioInfo === null  ? `<td style="font-size:10px; font-weight: 400;    width:50%;">
         Email &nbsp;&nbsp;: ${ FolioDetails ?
          OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' 
          :  FolioDetails?.Registration?.Guest?.Contact?.Email
            : FolioDetailsWithIDs?.Guest?.Contact?.Email}
       </td>`: '',
'!!NewItem2!!': newitem2,
'!!OutStandingBalance!!': outstandingbalanceFunc(),
    '!!eInvoiceQR!!': eInvoiceQr(),
    '!!UID!!': eInvoiceUniqueID(),
    '!!ExternalOutlet!!': iseInvoice() === true ? externalOutlet : '',
    '!!AmountItem!!': selectedItemDesc?.length > 0 ? AmountItem: '',
    '!!InvoiceRemark!!': HotelDetails?.DocumentRemark?.replace(/<p/g, '<div')?.replace(/<\/p>/g, '</div>') || '',
  '!!ReferenceNo!!': //phbtu, phkk
    (OutGoingDocTemplate !== null ? isNonGuestFolio ? 'N/A' : 
      FolioDetails?.Registration?.Booking?.ReferenceNo ||
      FolioDetails?.Booking?.ReferenceNo ||
      FolioDetailsWithIDs?.Booking?.ReferenceNo || '-'
      :
      FolioDetails?.Registration?.Booking?.ReferenceNo ||
      FolioDetails?.Booking?.ReferenceNo ||
      FolioDetailsWithIDs?.Booking?.ReferenceNo || '-')||'-'
      ,
    }




  var InvoiceTemplate = OutGoingDocTemplate !== null ? OutGoingDocTemplate?.Folio?.replace(
    /!!FOLIO!!|!!HotelImage!!|!!customheightfooter!!|!!NewItem2!!|!!ICtd!!|!!phonetd!!|!!phoneImperialtd!!|!!emailtd!!|!!OutStandingBalance!!|!!SubTotal!!|!!customheightcontent!!|!!IsSmfooter!!|!!FolioNo!!|!!BankDetail!!|!!QtyHeader!!|!!HotelCustomAddress!!|!!HotelPhone!!|!!HotelFaxNo!!|!!NewItem!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!TourismTaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!TTXRegNo!!|!!TINNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!ReferenceNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!CompanyName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!TaxSchemeItem!!|!!TotalRounding!!|!!eInvoiceQR!!|!!UID!!|!!ExternalOutlet!!|!!AmountItem!!|!!InvoiceRemark!!|!!ImperialLogo!!/gi,
    function (matched) {
      return mapObj[matched]
    }
  ): HotelXFolio?.replace(
    /!!FOLIO!!|!!HotelImage!!|!!FolioNo!!|!!NewItem!!|!!NewItem2!!|!!SubTotal!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!TourismTaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!TTXRegNo!!|!!TINNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!ReferenceNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!CompanyName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!TaxSchemeItem!!|!!TotalRounding!!|!!eInvoiceQR!!|!!UID!!|!!ExternalOutlet!!|!!AmountItem!!/gi,
    function (matched) {
      return mapObj[matched]
    }
  )

  return {
    InvoiceTemplate,
  }
}
